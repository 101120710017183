import TableLayout from "../../layout/main/TableLayout";
import {Column, IconWrapper, Row} from "../../../styles/CommonStyles";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import {TradeTypeBadge} from "../../../styles/main/orders";
import {getTradeTypeColor} from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import DateTime from "../../common/utils/DateTime";
import Tooltip from "../../common/utils/Tooltip";
import {DownloadIcon} from "../../../styles/main/setting";
import {BarLoader} from "react-spinners";
import useFiles from "../../../core/hooks/main/files/useFiles";
import {fileAttributes} from "../../../core/constants/headers";


const FilesTable = ({
    data
}) => {

    const { data: files } = data
    const { cs, headers } = fileAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        loading,
        selectedFile,
        onDownloadFile
    } = useFiles()

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
            hasWriteAccess={hasWriteAccess}
        >
            {files?.data?.map((file, idx) => (
                <Row index={idx} cs={cs} key={file.name}>
                    <Column>
                        {getTableIndex(idx)}
                    </Column>
                    <Column center>
                        {file.name}
                    </Column>
                    <Column center>
                        <TradeTypeBadge color={getTradeTypeColor(file.type)}>
                            <Text tid={file.type} />
                        </TradeTypeBadge>
                    </Column>
                    <Column center>
                        <TradeTypeBadge color={getTradeTypeColor(file.status)}>
                            <Text tid={file.status} />
                        </TradeTypeBadge>
                    </Column>
                    <Column center>
                        <DateTime dt={file.createdAt} />
                    </Column>
                    <Column center>
                        {hasWriteAccess &&
                            ((loading && selectedFile?.name === file.name) ?
                                    <BarLoader
                                        color={'#d6b357'}
                                        width={'48px'}
                                    />
                                    :
                                    <Tooltip
                                        content={'download-file'}
                                    >
                                        <IconWrapper onClick={() => onDownloadFile(file)}>
                                            <DownloadIcon size={24}/>
                                        </IconWrapper>
                                    </Tooltip>
                            )

                        }
                    </Column>

                </Row>
            ))}
        </TableLayout>
    )
}


export default FilesTable
