import styled, {css, keyframes} from "styled-components";
import {useEffect, useState} from "react";
import {ScaleLoader} from "react-spinners";



const ActionButton = (props) => {
    const {
        children,
        active,
        width,
        height,
        onClick,
        loading
    } = props

    const [coords, setCoords] = useState({ x: -1, y: -1 });
    const [isRippling, setIsRippling] = useState(false);

    useEffect(() => {
        if (coords.x !== -1 && coords.y !== -1) {
            setIsRippling(true);
            setTimeout(() => setIsRippling(false), 300);
        } else setIsRippling(false);
    }, [coords]);

    const onButtonClicked = (e) => {
        const rect = e.target.getBoundingClientRect();
        setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        onClick && !loading && onClick(e);
    }


    return(
        <TButton
            {...props}
            active={active}
            width={width}
            height={height}
            onClick={onButtonClicked}
        >
            <>
                {isRippling &&
                    <Ripple
                        style={{
                            left: coords.x,
                            top: coords.y
                        }}
                    />
                }
                {loading ?
                    <ScaleLoader
                        color={'#0c142a'}
                        height={26}
                        width={3}
                    />
                    :
                    children
                }
            </>

        </TButton>
    )
}


const TButton = styled.button`
  position: relative;
  overflow: hidden;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '52px'};
  transition: background 400ms;
  color: ${props => props.active ? '#000000' : props.theme.color};
  background-color: ${props => props.active ? props.theme.active : props.theme.mainBg};
  ${props => props.color && css`
    background-color: ${props => props?.active ? props.color : props.theme.mainBg};
  `};
  padding: 1rem 2rem;
  font-size: 1rem;
  outline: 0;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3); /* black with 30% opacity */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Vazir;
  font-width: 600;
  
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  };
`


const Animation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
`

const Ripple = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  background: #ffffff40;
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ${Animation};
`

export default ActionButton
