import styled, {css} from "styled-components";
import {useEffect, useState} from "react";
import { CgChevronDoubleRight, CgChevronDoubleLeft, CgChevronRight, CgChevronLeft } from 'react-icons/cg'
import {useMainContext} from "../../../core/contexts/main";
import {Flex} from "../../../styles/CommonStyles";
import Select from "../dropdown/Select";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";



const Pagination = ({
    total,
    page,
    onPageChange,
    limit,
    onLimitChange
}) => {

    const { width } = useWindowSize()
    const { lang } = useMainContext()

    const [lastPage, setLastPage] = useState(1)
    const [pages, setPages] = useState([])
    const [details, setDetails] = useState({
        start: 0, end: 0
    })


    useEffect(() => {

        if (page && limit && total) {
            setDetails({
                start: (page * limit - limit) + 1,
                end: page * limit
            })
            let lastPage = Math.ceil(total / limit)
            if (lastPage === 0) lastPage = 1
            setLastPage(lastPage)
        }else {
            setDetails({start: 0, end: 0})
            setLastPage(1)
        }

    }, [page, limit, total])


    const PRE_ACTIVE = page !== 1 ? 1 : 0
    const LAST_ACTIVE = page !== lastPage ? 1 : 0

    useEffect(() => {
        if (page && lastPage) {
            let temp = []
            if (page === 1) {
                for (let i = page; i <= lastPage && i < page + 3; i++) {
                    temp.push(i)
                }
                setPages(temp)
            }
            else if (page === lastPage) {
                for (let i = page; i > page - 3 && i > 0; i--) {
                    temp.push(i)
                }
                setPages(temp.reverse())
            }
            else {
                setPages([page - 1, page, page + 1])
            }
        }

    }, [lastPage, page])


    const onPageClicked = (idx) => {
        if (page !== idx) {
            onPageChange(idx)
        }
    }

    const onFirstIconClick = () => {
        if (PRE_ACTIVE) {
            onPageClicked(1)
        }
    }

    const onPreviousClick = () => {
        if (PRE_ACTIVE) {
            onPageClicked(page - 1)
        }
    }

    const onNextClick = () => {
        if (LAST_ACTIVE) {
            onPageClicked(page + 1)
        }
    }


    const onLastIconClick = () => {
        if (LAST_ACTIVE) {
            onPageClicked(lastPage)
        }
    }


    return (
        <Wrapper>
            <Flex>
                <Flex height='50px' style={{ margin: '0 10px' }}>
                    <Select
                        value={limit}
                        onValueChange={(idx) => onLimitChange(limitOptions[idx])}
                        options={limitOptions}
                        height='30px'
                        dropdownWidth='60px'
                        direction='top'
                        size='small'
                    />
                </Flex>
                <Details>
                    {lang === 'en' ?
                        `Results: ${details?.start}-${details?.end > total ? total : details?.end} of ${total}`
                        :
                        'نتایج: ' + `${details?.start}-${details?.end > total ? total : details?.end}` + ' از ' + total
                    }
                </Details>
            </Flex>


            <Flex style={{ direction: 'ltr' }}>
                <FirstIcon
                    active={PRE_ACTIVE}
                    size={width > TABLET_SIZE ? 22 : 18}
                    onClick={onFirstIconClick}
                />
                <Previous
                    active={PRE_ACTIVE}
                    size={width > TABLET_SIZE ? 22 : 18}
                    onClick={onPreviousClick}
                />

                {pages.map(item => (
                    <Page
                        key={item}
                        active={item === page ? 1 : 0}
                        onClick={() => onPageClicked(item)}
                    >
                        {item}
                    </Page>
                ))}

                <Next
                    active={LAST_ACTIVE}
                    size={width > TABLET_SIZE ? 22 : 18}
                    onClick={onNextClick}
                />
                <LastIcon
                    active={LAST_ACTIVE}
                    size={width > TABLET_SIZE ? 22 : 18}
                    onClick={onLastIconClick}
                />

            </Flex>
        </Wrapper>
    )
}


const limitOptions = [
    10, 20, 30, 40, 50
]



const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  @media screen and (max-width: 1050px){
    flex-direction: column-reverse;
  };
`

const Details = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme.color};
  
  @media screen and (max-width: 1050px) {
    font-size: 0.55rem;
  }
`

const FirstIcon = styled(CgChevronDoubleLeft)`
  color: ${props => props.theme.color};
  cursor: ${props => props.active ? 'pointer' : 'not-allowed'}
`

const LastIcon = styled(CgChevronDoubleRight)`
  color: ${props => props.theme.color};
  cursor: ${props => props.active ? 'pointer' : 'not-allowed'};
`

const Next = styled(CgChevronRight)`
  color: ${props => props.theme.color};
  cursor: ${props => props.active ? 'pointer' : 'not-allowed'};
`

const Previous = styled(CgChevronLeft)`
  color: ${props => props.theme.color};
  cursor: ${props => props.active ? 'pointer' : 'not-allowed'};
;
`

const Page = styled.div`
  color: ${props => props.theme.color};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 4px;
  margin: 0 2px;

  ${props => props.active && css`
    border: 1px solid ${props => props.theme.active};
  `};
  
  &:hover{
    background-color: ${props => props.theme.active};
    border: 1px solid ${props => props.theme.color}90;
    color: #0e1123;
  };
  
  @media screen and (max-width: 1050px) {
    width: 24px;
    height: 24px;
    font-size: 0.7rem;
  };
`


export default Pagination
