import styled from "styled-components";
import Skeleton from "../../../core/packages/skeleton";
import MainSkeleton from "../../skeleton/MainSkeleton";


const GlobalLoading = () => {

    return (
        <Wrapper>
            <Skeleton
                Content={MainSkeleton}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  background-color: #0c142a;
`

export default GlobalLoading;
